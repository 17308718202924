<template>
    <div>
        <b-card>
            <!-- Media -->
            <b-media>
                <template #aside>
                    <b-avatar
                        ref="previewEl"
                        class="margin-top-5"
                        :src="employee.avatar"
                        :text="avatarText(employee.name)"
                        :variant="`light-${resolveUserRoleVariant(employee.job_title)}`"
                        size="115px"
                    />
                </template>
                <h4 class="ml-1 mt-1">
                    {{ employee.name }}
                </h4>
                <div>
                    <span class="card-text ml-1"
                        >{{ employee.department }} |
                        {{ employee.job_title }}</span
                    >
                </div>
                <div class="d-sm-flex">
                    <b-form-checkbox
                        checked="true"
                        class="margin-top-5 ml-1 custom-control-success"
                        name="check-button"
                        disabled
                        switch
                    />
                    <span
                        v-if="employee.is_active"
                        class="card-text employee-status"
                        >(Active)</span
                    >
                </div>
            </b-media>
        </b-card>
        <b-card>
            <b-tabs>
                <b-tab title="Account details">
                    <!-- User Info: Input Fields -->
                    <b-form class="mt-1">
                        <b-row>
                            <!-- Field: Name -->
                            <b-col cols="12" md="3">
                                <b-form-group label="Name" label-for="name">
                                    <b-form-input
                                        id="name"
                                        v-model="employee.name"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Field: Email -->
                            <b-col cols="12" md="3">
                                <b-form-group label="Email" label-for="email">
                                    <b-form-input
                                        id="email"
                                        v-model="employee.email"
                                        type="email"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Field: Bob ID -->
                            <b-col cols="12" md="3">
                                <b-form-group label="Bob ID" label-for="bob-id">
                                    <b-form-input
                                        id="bob-id"
                                        v-model="employee.bob_id"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Field: Reports to -->
                            <b-col cols="12" md="3">
                                <b-form-group
                                    label="Reports to"
                                    label-for="reports_to"
                                >
                                    <b-form-input
                                        id="reports_to"
                                        v-model="employee.reports_to.name"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                    <div class="demo-spacing-0">
                        <b-alert class="mt-1" show variant="warning">
                            <div class="alert-body">
                                <feather-icon
                                    v-b-tooltip.hover.top="'Info'"
                                    class="mr-25"
                                    icon="InfoIcon"
                                />
                                <span class="ml-25"
                                    >If you would like to edit your account
                                    details, you must do so on Bob and it will
                                    be applied here automatically.</span
                                >
                            </div>
                        </b-alert>
                    </div>
                </b-tab>
                <b-tab title="Personal info">
                    <!-- User Info: Input Fields -->
                    <div style="float: right">
                        <b-button
                            v-if="edit === false"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-tooltip.hover.top="'Edit'"
                            variant="flat-secondary"
                            class="btn-icon"
                            aria-pressed="false"
                            @click="edit = edit ? false : true"
                        >
                            <feather-icon icon="EditIcon" size="1.3x" />
                        </b-button>
                    </div>
                    <b-form class="mt-1">
                        <b-row>
                            <!-- Field: NIF -->
                            <b-col cols="12" md="4">
                                <b-form-group label="NIF" label-for="nif">
                                    <b-form-input
                                        id="nif"
                                        v-model="employee.nif"
                                        :disabled="!edit"
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Field: Vehicle -->
                            <b-col cols="12" md="4">
                                <b-form-group
                                    label="Vehicle"
                                    label-for="vehicle"
                                >
                                    <b-form-input
                                        id="vehicle"
                                        v-model="employee.vehicle"
                                        :disabled="!edit"
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Field: Signature -->
                            <b-col cols="12" md="4">
                                <label for="wildcard">Signature</label>
                                <b-form-file
                                    v-model="employee.upload_signature"
                                    placeholder="Choose an image or drag it here"
                                    drop-placeholder="Drop file here..."
                                    :disabled="!edit"
                                />
                                <b-card-text
                                    v-if="employee.upload_signature"
                                    class="signature-link"
                                >
                                    Selected file:
                                    {{ employee.upload_signature }}
                                </b-card-text>
                            </b-col>
                        </b-row>
                    </b-form>
                    <!-- Action Buttons -->
                    <div
                        v-if="edit === true"
                        class="demo-inline-spacing"
                        style="float: right"
                    >
                        <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-primary"
                            class="mt-1"
                            @click="save()"
                        >
                            <feather-icon icon="SaveIcon" class="mr-50" />
                            <span class="align-middle">Save</span>
                        </b-button>
                        <b-button
                            class="mt-1"
                            variant="outline-secondary"
                            @click="edit = false"
                        >
                            Cancel
                        </b-button>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BTabs,
    BTab,
    BAlert,
    BFormFile,
    VBTooltip,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { avatarText } from "@core/utils/filter"
import vSelect from "vue-select"
import Auth from "@/api/services/Auth"
import Ripple from "vue-ripple-directive"

export default {
    components: {
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        BTabs,
        BTab,
        BAlert,
        BFormFile,
        ToastificationContent,
        vSelect,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            employee: {},
            reportsTo: {},
            edit: false,
            avatarText,
        }
    },
    async created() {
        this.$loading(true)
        try {
            const response = await Auth.employee()
            this.employee = response?.data
        } catch (error) {
            console.log(error)
        } finally {
            this.$loading(false)
        }
    },
    methods: {
        resolveUserRoleVariant(role) {
            if (role === "subscriber") return "primary"
            if (role === "author") return "warning"
            if (role === "maintainer") return "success"
            if (role === "editor") return "info"
            if (role === "admin") return "danger"
            return "primary"
        },
        save() {
            Auth.employeePut({
                id: this.employee.id,
                is_active: this.employee.is_active,
                nif: this.employee.nif,
                vehicle: this.employee.vehicle,
                name: this.employee.name,
                reports_to: this.employee.reports_to.id,
            })
                .then((response) => {
                    this.edit = false
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Success",
                                icon: "CheckCircleIcon",
                                text: "Your changes have been successfully saved!",
                                variant: "success",
                            },
                        },
                        {
                            position: "top-center",
                        }
                    )
                })
                .catch(() => {
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "AlertTriangleIcon",
                                text: "Something was wrong. Please try again.",
                                variant: "danger",
                            },
                        },
                        {
                            position: "bottom-center",
                        }
                    )
                })
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
